<template>
  <span class="regular-12 text-black text-start" v-if="edit">
    <v-select
      v-model:options="searchItems"
      v-on:search="(s, k) => fetchCrmItem(s, k)"
      :filterable="false"
      v-model="value"
      label="name"
      multiple
      :placeholder="$t(`assetFinder.addANewItem`)"
      :reduce="(c) => c"
      v-on:focusout="edit = false"
      append-to-body
      :dropdown-should-open="() => edit"
    />
  </span>
  <span v-for="item in value" :key="item.id" v-show="!edit">
    <button
      class="btn btn-transparent btn-sm badge badge-outline me-1"
      v-on:click="edit = true"
    >
      {{ item?.name }}
    </button>
  </span>
  <span class="regular-12 text-black text-start" v-if="add">
    <v-select
      v-model:options="searchItems"
      v-on:search="(s, k) => fetchCrmItem(s, k)"
      :filterable="false"
      v-on:option:selecting="addNew"
      label="name"
      :placeholder="$t(`assetFinder.addANewItem`)"
      :reduce="(c) => c"
      v-on:focusout="add = false"
      append-to-body
      :dropdown-should-open="() => add"
    />
  </span>
  <span v-else-if="!edit">
    <button
      class="btn btn-secondary btn-sm"
      :title="$t(`assetFinder.viewDeliveryStatus`)"
      v-on:click="add = true"
    >
      <BaseIcon name="plus-circle" />
    </button>
  </span>
</template>

<script>
import BaseIcon from "../../icons/BaseIcon";
import http from "../../../modules/http";
export default {
  name: "CrmItemMultipleFinder",
  components: { BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Number,
  },
  data() {
    return {
      value: this.modelValue,
      searchItems: [],
      edit: false,
      add: false,
    };
  },
  watch: {
    add() {
      this.searchItems = [];
    },
    edit() {
      this.searchItems = [];
    },
    value: {
      deep: true,
      handler() {
        this.$emit("update:modelValue", this.value);
        this.$emit("update", this.value);
      },
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "update"],
  methods: {
    addNew(value) {
      this.value.push(value);
      this.add = false;
    },
    fetchCrmItem(search, loading) {
      if (search.length < 3) {
        return;
      }
      loading(true);
      http
        .fetch("/crm/teams/search", {
          q: search,
        })
        .then((data) => {
          this.searchItems = data;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
  },
};
</script>
